import isNetworkError from 'is-network-error'
import pRetry, { AbortError } from 'p-retry'
import type { Options } from 'p-retry'

export function retryOnNetworkError<T>(
  callback: () => Promise<T>,
  options?: Options,
): Promise<T> {
  const wrappedCallback = async () => {
    try {
      return await callback()
    } catch (error) {
      if (error instanceof Error) {
        if (isNetworkError(error)) {
          throw error
        }
        throw new AbortError(error)
      } else {
        throw new AbortError(String(error))
      }
    }
  }

  return pRetry(wrappedCallback, options)
}
